// extracted by mini-css-extract-plugin
export var background = "About-module--background--300d4";
export var contactHeader = "About-module--contactHeader--57c26";
export var container = "About-module--container--5363a";
export var defaultHeader = "About-module--defaultHeader--7c348";
export var experienceHeader = "About-module--experienceHeader--d8441";
export var header = "About-module--header--62015";
export var header2 = "About-module--header2--645e3";
export var highLight1 = "About-module--highLight1--0bab5";
export var highLight2 = "About-module--highLight2--86e2c";
export var highLight3 = "About-module--highLight3--c1913";
export var paragraph = "About-module--paragraph--4e726";
export var toolboxHeader = "About-module--toolboxHeader--b40f1";
export var wrapper = "About-module--wrapper--63b55";